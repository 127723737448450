import { useState } from "react";
import { MerchantType, openCloseMerchant, updateMerchant } from "../../../../api/merchants";
import EditMerchantModal from "../../../../components/Modals/EditMerchantModal";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Fieldset } from "primereact/fieldset";
import { toast } from "react-toastify";
import { InputMask } from "primereact/inputmask";

interface Props {
    isAdmin: boolean;
    merchant: MerchantType;
    setMerchant: (merchant: MerchantType) => void;
   }

   const MerchantInfo: React.FC<Props> = ({isAdmin, merchant, setMerchant}) => {
    const [editModal, setEditModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<string>();
    const [endTime, setEndTime] = useState<string>();
    const setIsOpen = async () => {
        if(merchant?.id) {
            setLoading(true);
            const newMerchant = await openCloseMerchant(merchant.id, !merchant.isOpen);
            if (newMerchant) setMerchant(newMerchant);
            setLoading(false);
        }
    }
    const removeOH = async (day: string, time: string, isJE: boolean) => {
        const openingHours = isJE ? {...merchant.je_openingHours} : {...merchant.openingHours};
        switch(day) {
            case 'monday':
                openingHours.monday = openingHours.monday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
            case 'tuesday':
                openingHours.tuesday = openingHours.tuesday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
            case 'wednesday':
                openingHours.wednesday = openingHours.wednesday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
            case 'thursday':
                openingHours.thursday = openingHours.thursday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
            case 'friday':
                openingHours.friday = openingHours.friday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
            case 'saturday':
                openingHours.saturday = openingHours.saturday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
            case 'sunday':
                openingHours.sunday = openingHours.sunday.filter((v: {start_time: string}) => v.start_time !== time);
                break;
        }
        try {
            if (isJE) {
                const newMerchant = await updateMerchant({ ...merchant, je_openingHours: openingHours});
                setMerchant && newMerchant && setMerchant(newMerchant);
            } else {
                const newMerchant = await updateMerchant({ ...merchant, openingHours});
                setMerchant && newMerchant && setMerchant(newMerchant);
            }
        } catch (err) {
            toast.error(err as string);
        }
    }
    const addOH = async (day: string, isJE: boolean) => {
        const openingHours = isJE ? {...merchant.je_openingHours} : {...merchant.openingHours};
        if (!startTime || !endTime) {
            toast.error('Please enter a valid time');
            return;
        }
        switch(day) {
            case 'monday':
                openingHours.monday = [...openingHours.monday, {start_time: startTime, end_time: endTime}];
                break;
            case 'tuesday':
                openingHours.tuesday = [...openingHours.tuesday, {start_time: startTime, end_time: endTime}];
                break;
            case 'wednesday':
                openingHours.wednesday = [...openingHours.wednesday, {start_time: startTime, end_time: endTime}];
                break;
            case 'thursday':
                openingHours.thursday = [...openingHours.thursday, {start_time: startTime, end_time: endTime}];
                break;
            case 'friday':
                openingHours.friday = [...openingHours.friday, {start_time: startTime, end_time: endTime}];
                break;
            case 'saturday':
                openingHours.saturday = [...openingHours.saturday, {start_time: startTime, end_time: endTime}];
                break;
            case 'sunday':
                openingHours.sunday = [...openingHours.sunday, {start_time: startTime, end_time: endTime}];
                break;
        }
        console.log(openingHours);
        try {
            if (isJE) {
                const newMerchant = await updateMerchant({ ...merchant, je_openingHours: openingHours});
                setMerchant && newMerchant && setMerchant(newMerchant);
            } else {
                const newMerchant = await updateMerchant({ ...merchant, openingHours});
                setMerchant && newMerchant && setMerchant(newMerchant);
            }
        } catch (err) {
            toast.error(err as string);
        }
    }
    const dayToGerman = (day: string) => {
        switch(day) {
            case 'monday':
                return 'Montag';
            case 'tuesday':
                return 'Dienstag';
            case 'wednesday':
                return 'Mittwoch';
            case 'thursday':
                return 'Donnerstag';
            case 'friday':
                return 'Freitag';
            case 'saturday':
                return 'Samstag';
            case 'sunday':
                return 'Sonntag';
        }
    }
    return (<div className="card">
        {merchant && isAdmin && <EditMerchantModal setLoading={setLoading} merchant={merchant} isOpen={editModal} closeModal={() => setEditModal(false)} setMerchant={setMerchant}/>}
        <div className="pb-5">
            <div className="flex items-center justify-between">
                <Card title={merchant.name} className="w-2/3">
                    <Fieldset legend="Vertragspartner">
                        <p>{merchant.contractor}</p>
                    </Fieldset>
                </Card>
                <div className="flex flex-col gap-2">
                    {loading && <ProgressSpinner />}
                    <Button
                            type="button"
                            className={`mr-2 relative inline-flex items-center rounded-md border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${merchant.isOpen ? "bg-customgreen border-customgreen hover:text-customgreen focus-visible:outline-customgreen" : "bg-red-500 border-red-500 hover:text-red-500 focus-visible:outline-red-500"}`}
                            onClick={setIsOpen}
                        >{merchant.isOpen ? 'Lager Schließen' : 'Lager Öffnen'}</Button>
                    <Button 
                        className="mr-2 relative inline-flex items-center rounded-md bg-secondary-1 border-secondary-1 border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-secondary-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-1"
                        onClick={() => setEditModal(true)}
                    >Edit Lager</Button>
                </div>
            </div>
        </div>
        <div className="pb-5">
            <div className="flex items-center justify-between">
                <Card title={'Öffnungszeiten'} className="w-2/3">
                {merchant.openingHours && Object.entries(merchant.openingHours).map(([key, value]) => (
                    <Fieldset key={key} legend={dayToGerman(key)} className="capitalize">
                        {value.map((v: {start_time: string, end_time: string}) => (
                            <div key={`${key}-${v.start_time}`} className="flex gap-2 items-center justify-between border-b p-2">
                                <p>{v.start_time} - {v.end_time}</p>
                                <Button className="pi pi-trash" onClick={() => removeOH(key, v.start_time, false)} />
                            </div>
                        ))}
                        <div className="flex gap-2 items-center justify-between border-b p-2">
                            <div className="flex gap-2">
                            <InputMask className="border" value={startTime} onChange={(e) => e.target.value && setStartTime(e.target.value)} mask="99:99" placeholder="00:00" />
                            <span>-</span>
                            <InputMask className="border" value={endTime} onChange={(e) => e.target.value && setEndTime(e.target.value)} mask="99:99" placeholder="24:00" />
                            </div>
                            <Button className="pi pi-plus" onClick={() => addOH(key, false)} />
                        </div>
                    </Fieldset>
                ))}
                </Card>
            </div>
            <div className="flex items-center justify-between">
                <Card title={'Justeat Öffnungszeiten'} className="w-2/3">
                {merchant.je_openingHours && Object.entries(merchant.je_openingHours).map(([key, value]) => (
                    <Fieldset key={"je-"+key} legend={dayToGerman(key)} className="capitalize">
                        {value.map((v: {start_time: string, end_time: string}) => (
                            <div key={`${key}-${v.start_time}`} className="flex gap-2 items-center justify-between border-b p-2">
                                <p>{v.start_time} - {v.end_time}</p>
                                <Button className="pi pi-trash" onClick={() => removeOH(key, v.start_time, true)} />
                            </div>
                        ))}
                        <div className="flex gap-2 items-center justify-between border-b p-2">
                            <div className="flex gap-2">
                            <InputMask className="border" value={startTime} onChange={(e) => e.target.value && setStartTime(e.target.value)} mask="99:99" placeholder="00:00" />
                            <span>-</span>
                            <InputMask className="border" value={endTime} onChange={(e) => e.target.value && setEndTime(e.target.value)} mask="99:99" placeholder="24:00" />
                            </div>
                            <Button className="pi pi-plus" onClick={() => addOH(key, true)} />
                        </div>
                    </Fieldset>
                ))}
                </Card>
            </div>
        </div>
    </div>)
   }

   export default MerchantInfo;